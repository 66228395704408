import React from "react"
import { StaticQuery } from 'gatsby'
import { connect } from 'react-redux';
import ProductID from "../components/productID"
import { updateDesign, updateStage, updateStock } from '../actions';

const mapStateToProps = ({ currentDesign, currentStage, currentRegion, currentStock, currentLang }) => {
  return { currentDesign, currentStage, currentRegion, currentStock, currentLang }
}

const mapDispatchToProps = dispatch => ({
  updateDesign: (designValue) => dispatch(updateDesign(designValue)),
  updateStage: (stageValue) => dispatch(updateStage(stageValue)),
  updateStock: (stockValue) => dispatch(updateStock(stockValue))
})

const quickPocketPage = ({ currentDesign, updateDesign, currentStage, currentRegion, updateStage, currentStock, updateStock, currentLang }) => (
  <ProductID
    product="quick-pocket"
    currentStage={currentStage}
    currentDesign={currentDesign}
    updateDesign={updateDesign}
    currentRegion={currentRegion}
    currentStage={currentStage}
    updateStage={updateStage}
    updateStock={updateStock}
    currentStock={currentStock}
    currentLang={currentLang}
    classname="quickPocket"
  />
)

export default connect(mapStateToProps, mapDispatchToProps)(quickPocketPage);